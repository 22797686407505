import React  from "react";

// Splide Slider 
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';


class Slider extends React.Component {
    constructor( props ) {
		super( props );

		this.primaryRef   = React.createRef();
        this.secondaryRef = React.createRef();
        
        this.hasMounted = false;
    }
     
    componentDidMount() {
        // console.log("Slider Component Mounted");

        this.hasMounted = true;
        this.forceUpdate();
    }

    componentDidUpdate() {
        // console.log("Slider Component Updated");
       // Set the sync target right after the component is mounted.
       this.primaryRef.current.sync(this.secondaryRef.current.splide);

    }
    
    
    render() {

        if(!this.hasMounted) {
            return null;
        }

		const primaryOptions = {
			rewind : true,
            height  : 470,
            width: 470,
            cover: true,
            gap    : '1rem',
            breakpoints: {
                768: {
                    width: "90vw",
                    height: 310,
                },
            }
		};

		const secondaryOptions = {
			type        : 'slide',
			rewind      : true,
			width       : 470,
			gap         : '1rem',
			pagination  : false,
			fixedWidth  : 110,
			fixedHeight : 70,
			cover       : true,
			focus       : 'center',
			isNavigation: true,
            updateOnMove: true,
            breakpoints: {
                768: {
                    width: "90vw",
                    fixedWidth  : 81.4,
                    fixedHeight : 52.5,
                },
            }
		};

        

		return (
			<>
				<Splide options={ primaryOptions } ref={ this.primaryRef } key="primary-slider">
                    {this.props.images.map((image, index) => {
                        return (
                            <SplideSlide key={index}>
                                <img src={image} alt={`${this.props.product.Name} product preview`} />
                            </SplideSlide>
                        )
                    })}
				</Splide>

				<Splide options={ secondaryOptions } ref={ this.secondaryRef } key="secondary-slider">
                    {this.props.images.map((image, index) => {
                        return (
                            <SplideSlide key={index}>
                                <img src={image} alt={`${this.props.product.Name} product preview`} />
                            </SplideSlide>
                        )
                    })}
				</Splide>
			</>
		);
    }   
}


export default Slider;