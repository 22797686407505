import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Slider from '../components/slider';
import Banner from '../components/banner';
import { Helmet } from "react-helmet";

// IMAGES
import netballers from '../images/netballers.jpg';

const Product = ({ pageContext }) => {
  let images = [];
  
  for(let i = 0; i <= (pageContext.ImageCount - 1); i++) {
    images.push(`https://trends.nz/resizer/470/${pageContext.Code}-${i}.jpg`);
  }

  return (
    <Layout>
      <Helmet>
        <title>{`${pageContext.Name} | #${pageContext.Code} | Products By Design`}</title>
      </Helmet>
      <div className="container">
        <div className="product-header">
          <div className="product-header__title">
            <h1>{pageContext.Name}</h1>
          </div>
          <div className="product-header__button">
            <Link to={`/contact?product=${pageContext.Code}`}><button>Get a Quote</button></Link>
          </div>
        </div>

        <div className="product-columns">
          <div className="product-description">
            <Description title="Description" value={pageContext.Description} />
            <Description title="Dimension #1" value={pageContext.Dimension1} />
            <Description title="Dimension #2" value={pageContext.Dimension2} />
            <Description title={pageContext.PrintType1} value={pageContext.PrintDescription1} />
            <Description title={pageContext.PrintType2} value={pageContext.PrintDescription2} />
            <Description title={pageContext.PrintType3} value={pageContext.PrintDescription3} />
            <Description title={pageContext.PrintType4} value={pageContext.PrintDescription4} />
            <Description title={pageContext.PrintType5} value={pageContext.PrintDescription5} />
            <Description title={pageContext.PrintType6} value={pageContext.PrintDescription6} />
            <Description title={pageContext.PrintType7} value={pageContext.PrintDescription7} />
            <Description title={pageContext.PrintType8} value={pageContext.PrintDescription8} />
            <Description title="Carton Length" value={pageContext.CartonLength} />
            <Description title="Carton Width" value={pageContext.CartonWidth} />
            <Description title="Carton Height" value={pageContext.CartonHeight} />
            <Description title="Carton Quantity" value={pageContext.CartonQuantity} />
            <Description title="Carton Weight" value={pageContext.CartonWeight} postfix="KG" />
          </div>
          <div className="product-images">
              <Slider images={images} product={pageContext} />
          </div>
        </div>
      </div>
      
    <Banner
      title="Get a quote"
      description="Interested in any of our products or have a question? Send us a message and we'll get back to you shortly."
      button={{
        title: "Contact Us",
        url: "/contact"
      }}
      image={netballers}
      alignment="50% 20%"
    />
    </Layout>
  )
}

const Description = ({ title, value, postfix }) => {
  if(!title || !value) return null;

  return (
    <>
      <p><strong>{title}</strong></p>
      <p>{value}{postfix}</p>
    </>
  )
}

export default Product;